<template>
  <div class="pricing-div" v-if="plans">
    <template v-if="standardPackages">
      <div class="package-price-wrapper">
        <div v-for="plan in standardPackages" :key="plan.key" class="standard-packages">
          <div class="content-wrapper">
            <div class="image-block">
              <img v-if="plan.name === 'Essential'" src="@/assets/lite.png" />
              <img v-else-if="plan.name === 'Business'" src="@/assets/pro.png" />
              <img v-else-if="plan.name === 'Business +'" src="@/assets/premium.png" />
            </div>
            <a v-if="plan.name !== 'Business +'"
              :href="'/v5/package/'+plan.name.split(' ').join('-').toLowerCase()" class="title">
              <span>{{plan.name.split(' ')[0]}}</span>
              {{plan.name.split(' ')[1]}} {{plan.name.split(' ')[2]}}
            </a>
            <a v-else
              :href="'/v5/package/business+'" class="title">
              <span>{{plan.name.split(' ')[0]}}</span>
              {{plan.name.split(' ')[1]}} {{plan.name.split(' ')[2]}}
            </a>
            <p class="subtitle" v-if="plan.subtitle">{{plan.subtitle}}</p>
          </div>
          <div class="pricing">
            <p class="annual-price" v-if="plan.price.price_by_volume_discounts">
              {{formatCurrency(plan.price.price_by_volume_discounts["12"][currency], currency)}}
              <span>/month</span>
            </p>
            <span class="unit-label">billed annually</span>
            <p class="unit-label" v-if="plan.price.unit_amount">
              <span>{{formatCurrency(plan.price.unit_amount[currency], currency)}}</span>
              billed monthly
            </p>
          </div>
          <TextButton
            class="license-cta"
            :buttonType="'rs-button black-cta lg'"
            :link="licenseLink(plan.key)"
          />
          <rz-markdown :text="plan.description" v-if="plan.description"></rz-markdown>
          <span class="markdown list-view">
            <ul>
              <li>Access to
                <strong v-if="plan.name === 'Essential'" @click="$emit('showPopup', plan)">14 APIs</strong>
                <strong v-if="plan.name === 'Business'" @click="$emit('showPopup', plan)">21 APIs</strong>
                <strong v-if="plan.name === 'Business +'" @click="$emit('showPopup', plan)">25+ APIs</strong>
              </li>
            </ul>
          </span>
        </div>
        <div class="enterprise">
          <div class="content-wrapper">
            <div class="image-block">
              <img src="@/assets/enterprise.png" />
            </div>
            <a href="/v5/contact-sales" class="title"><span>Enterprise</span></a>
            <p class="subtitle">Take control of Cricket API to create your custom package</p>
          </div>
          <div class="pricing">
            <img src="@/assets/client-logos.png" />
          </div>
          <a href="/v5/contact-sales" class="contact-sales-btn"
          >Contact sales</a>
          <p class="description">
           <ul>
              <li><strong>Custom</strong> API Endpoints</li>
              <li><strong>Flexible</strong> subscription plan</li>
              <li>Integration support</li>
              <li><strong>Tech</strong> <strong>Assistance</strong></li>
              <li>Streamlined <strong>DevOps</strong> process</li>
              <li>Inclusive of <strong>SLA</strong></li>
              <li>Dedicated Account Manager</li>
              <li>Complete access to APIs</li>
            </ul>
          </p>
        </div>
      </div>
      <div class="compare-plans">
        <a href="/v5/package-pricing">See all Features</a>
      </div>
      <h3 class="pricing-subtitle">Other Packages</h3>
    </template>
    <div class="package-plans-section" v-if="packagePlans" :class="[!isPayment ? 'package-info': '']">
      <div class="section-wrapper">
        <div class="plan-box-wrapper">
          <div v-for="(planInfo, key) in packagePlans" :key="key" :class="'plan-box plan-box-' + (key%3)">
            <div class="image-wrapper" :class="planInfo.key">
              <img src="@/assets/payment/package-img.png" />
              <template v-if="planInfo.show_early_bird_pricing">
                <span class="flag-text"
                  v-if="planInfo.early_bird_pricing_notes">
                  {{planInfo.early_bird_pricing_notes}}
                </span>
                <span class="flag-text"
                  v-else>
                  Early Bird Price
                </span>
              </template>
            </div>
            <div class="content-wrapper">
              <div class="plan-inner-wrapper">
                <div class="align-left">
                  <h4 v-if="planInfo.show_resource_page">
                    <a :href="'/v5/package/'+planInfo.name.split(' ').join('-').toLowerCase()">
                    {{planInfo.name}}</a>
                  </h4>
                  <h4 @click="$emit('showPopup', planInfo)"
                    v-else>
                    {{planInfo.name}}</h4>
                  <!-- <p v-if="isPayment" class="access-link" @click="$emit('showPopup', planInfo)">
                    View Access</p> -->
                </div>
                <div class="align-right">
                  <span class="price">{{formatCurrency(planInfo.price.unit_amount[currency], currency)}}</span>
                  <template v-if="!planInfo.price.unit_label || planInfo.price.unit_label === ''">
                    <span v-if="planInfo.type === 'association_package'"
                    class="unit-label">Per month when billed annually</span>
                    <span v-else class="unit-label">For entire tournament</span>
                  </template>
                  <span v-else class="unit-label">{{planInfo.price.unit_label}}</span>
                </div>
              </div>
              <rz-markdown v-if="planInfo.description" :text="planInfo.description"></rz-markdown>
              <div class="button-wrapper">
                <a class="access-link" v-if="planInfo.show_resource_page"
                  :href="'/v5/package/'+planInfo.name.split(' ').join('-').toLowerCase()">
                  View Access</a>
                <p class="access-link"
                  v-else
                  @click="$emit('showPopup', planInfo)">
                  View Access
                </p>
                <a v-if="!isPayment"
                  :href="'/v5/user/project/review/?package='+planInfo.key+'&step=ReviewPlan'" class="buy-btn">
                  Get Started
                </a>
                <button v-else class="select-btn" @click="nextStep('package', planInfo.key)">
                Select
              </button>
            </div>
          </div>
          </div>
          <div class="plan-box paygo">
            <div class="image-wrapper paygo">
              <img src="@/assets/payment/paygo-img.png"/>
            </div>
            <div class="content-wrapper">
              <h4><a href="/v5/get-started">Pay as you go</a></h4>
              <p>Access to all tournaments, all matches, stats and all API.
                Also access to historical data with Enterprise plan.
                Pay only for what you use.
                This is most commonly used by our customers.</p>
              <router-link
                class="learn-more-btn" v-if="isPayment"
                :to="{ name: 'rsPricing' }"
                target="_blank"
                title="Explore Pricing" rel="noopener">
                Explore Pricing
              </router-link>
              <div class="button-wrapper paygo-buttons">
                <template v-if="!isPayment">
                  <router-link
                    :to="{ name: 'rsPricing' }" class="access-link"
                    title="Explore Pricing" rel="noopener">
                    Explore Pricing
                  </router-link>
                  <a
                    href="/v5/user/project/review/?step=ChooseLicense" class="buy-btn"
                    title="Get Started" rel="noopener">
                    Get Started
                  </a>
                </template>
                <button v-else class="select-btn" @click="nextStep('paygo')">
                  Select
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { WizardStep } from '@/store/modules/payments';
import TextButton from '@/components/RZWebsite/TextButton.vue';

export default {
  props: {
    packagePlans: Array,
    currency: String,
    isPayment: Boolean,
    showStandardPackage: Boolean,
  },
  components: {
    TextButton,
  },
  data() {
    return {
      standardPackages: null,
    };
  },
  computed: {
    ...mapState({
      wizard: (state) => state.payments.wizard,
      createProjectState: (state) => state.payments.createProjectState,
      projectSetupState: (state) => state.payments.projectSetupState,
      cartPreviewInput: (state) => state.payments.cartPreviewInput,
      currency: (state) => state.user.activeCurrency,
    }),
    plans() {
      if (this.showStandardPackage && this.packagePlans) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.standardPackages = this.packagePlans.filter((plan) => plan.type === 'standard_package'
        && plan.active && plan.show_in_website && plan.name !== 'Developer Kit');
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.packagePlans = this.packagePlans.filter((plan) => (plan.type !== 'standard_package'
          || plan.name === 'Developer Kit')
          && plan.active && plan.show_in_website);
      }
      return this.packagePlans;
    },
  },
  methods: {
    licenseLink(key) {
      return {
        name: 'Get Started',
        routeParams: {
          name: 'projectCreation',
          query: { package: key, quantity: 12 },
        },
      };
    },
    nextStep(plan, product) {
      if (plan === 'paygo') {
        this.$store.commit('payments/updateWizard', { step: WizardStep.ChooseLicense });
        if (this.cartPreviewInput && this.cartPreviewInput.prepayAmountKey && this.cartPreviewInput.prepayAmount) {
          this.cartPreviewInput.prepayAmountKey = null;
          this.cartPreviewInput.prepayAmount = null;
        }
      } else {
        this.$router.push({
          name: 'planReview',
          query: {
            package: product,
          },
        });
        this.$store.commit('payments/updateCartPreviewParam', {
          selectedLicense: 'package',
        });
        this.$store.commit('payments/updateWizard', { step: WizardStep.ReviewPlan });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .package-plans-section {
    .section-wrapper {
      .plan-box-wrapper {
        display: block;
        @media screen and (min-width: $breakpoint-md) {
          display: flex;
          flex-wrap: wrap;
          margin-bottom: size(50);
        }
      }
      div {
        &.plan-box {
          display: inline-block;
          position: relative;
          margin: 0;
          width: 100%;
          margin-bottom: size(30);
          border-radius: size(8);
          background: var(--rs-white-color);
          box-shadow: 0 size(4) size(20) rgba(51, 51, 51, 0.12);
          @media screen and (min-width: $breakpoint-md) {
            margin-right: size(10);
            width: size(310);
            margin-bottom: size(10);
          }
          @media screen and (min-width: $breakpoint-lg) {
            &:nth-child(3) {
              margin-right: 0;
            }
            // min-height: size(300);
          }
          .flag-text {
            position: absolute;
            bottom: size(10);
            right: size(20);
            padding: size(4) size(8);
            font-size: size(9);
            line-height: size(13);
            background:
            linear-gradient(90deg,#0000 33%,rgb(250 243 176) 50%,#0000 66%) #F6E337;
            text-transform: uppercase;
            letter-spacing: 0.01em;
            font-weight: 600;
            color: var(--rs-black-color);
            border-radius: size(4);
            background-size:300% 100%;
            animation: shine 1.5s infinite;
            @keyframes shine {
              0% {
                background-position: right;
              }
            }
          }
          &:last-child {
            margin-right: 0;
          }
          h4 {
            padding-bottom: size(8);
            cursor: pointer;
            color: var(--rs-black-color);
            a {
              color: var(--rs-black-color);
            }
          }
          p {
            font-size: size(12);
            line-height: size(20);
            padding-bottom: size(8);
            span {
              font-size: size(12);
              line-height: size(20);
              text-transform: capitalize;
            }
          }
          .image-wrapper {
            border-top-left-radius: size(8);
            border-top-right-radius: size(8);
            position: relative;
            height: size(80);
            img {
              width: size(88);
              position: absolute;
              bottom: 0;
            }
            &.paygo {
              background: linear-gradient(0deg, #97E6AB, #97E6AB), #79CEA5;
              img {
                display: inline-block;
                vertical-align: bottom;
                width: size(200);
              }
            }
            &.rs_package_icc_wc_t20_2022_6f3, &.rs_package_iplt20_2022_689,
            &.rs_package_c__board__icc__c2ab7ee61_1ad, &.rs_package_icc_wc_t20_2022_177,
            &.rs_package_c__board__icc__c2ab7ee61_72a {
              img {
                width: size(88);
                position: absolute;
                bottom: 0;
              }
            }
          }
          &.plan-box-0 {
            .image-wrapper {
              background: #A9C6FC;
            }
          }
          &.plan-box-1 {
            .image-wrapper {
              background: #6FCAF2;
            }
          }
          &.plan-box-2 {
            .image-wrapper {
              background: #93E8EB;
            }
          }
          .content-wrapper {
            padding: size(20);
            border-bottom-left-radius: size(8);
            border-bottom-right-radius: size(8);
            background: var(--rs-white-color);
            h4 {
              display: inline-block;
              font-size: size(18);
              line-height: size(28);
              color: var(--rs-black-color);
              font-weight: 500;
              padding-bottom: size(4);
              max-width: size(140);
              padding-right: size(8);
              a {
                &:hover {
                  color: var(--rz-link-color);
                }
              }
              &:hover {
                color: var(--rz-link-color);
              }
            }
            .markdown {
              .p {
                font-size: size(12);
                line-height: size(17);
                padding-top: size(10);
                color: #000000;
              }
            }
            .plan-inner-wrapper {
              &::after {
                content: '';
                clear: both;
                display: block;
              }
            }
            .learn-more-btn {
              display: block;
              font-weight: 400;
              font-size: size(12);
              line-height: size(17);
              color: #585757;
              cursor: pointer;
              text-decoration: underline;
              &:hover {
                color: var(--rz-link-color);
              }
            }
            span {
              &.price {
                display: block;
                font-weight: 700;
                font-size: size(18);
                line-height: size(24);
                color: var(--rs-black-color);
              }
              &.label {
                font-size: size(10);
                line-height: size(12);
                color: #585757;
                display: block;
                max-width: size(130);
                position: absolute;
                text-transform: uppercase;
                top: size(-12);
              }
              &.unit-label {
                font-size: size(12);
                line-height: size(17);
                color: #777;
                font-weight: 400;
                display: block;
                max-width: size(130);
                padding-top: size(4);
              }
            }
            .access-link {
              display: block;
              font-weight: 400;
              font-size: size(12);
              line-height: size(17);
              color: #585757;
              cursor: pointer;
              padding-top: size(14);
              text-decoration: underline;
              &:hover {
                color: var(--rz-link-color);
              }
            }
            .align-left {
              display: inline-block;
            }
            .align-right {
              float: right;
              max-width: size(110);
              text-align: right;
            }
          }
          .buy-btn {
            display: inline-block;
            font-size: size(12);
            line-height: size(15);
            font-weight: 700;
            color: var(--rs-white-color);
            border: size(1) solid var(--hero-text);
            padding: size(12);
            background: var(--hero-text);
            border-radius: size(5);
            width: size(120);
            margin-top: 0;
            text-align: center;
            cursor: pointer;
            &:hover {
              @include jump-up-animation;
            }
            &.get-started {
              display: block;
              text-align: center;
            }
          }
          .select-btn {
            display: inline-block;
            font-size: size(12);
            line-height: size(15);
            color: var(--rs-white-color);
            border: size(1) solid var(--hero-text);
            padding: size(12);
            background: var(--hero-text);
            border-radius: size(5);
            width: size(120);
            text-align: center;
            margin-top: 0;
            cursor: pointer;
            &:hover {
              @include jump-up-animation;
            }
          }
          .explore-btn {
            display: inline-block;
            font-size: size(12);
            line-height: size(15);
            color: var(--rs-black-color);
            border: size(1) solid var(--rs-black-color);
            padding: size(12);
            font-weight: 700;
            border-radius: size(5);
            width: size(120);
            text-align: center;
            margin-top: 0;
            cursor: pointer;
            &:hover {
              @include jump-up-animation;
            }
          }
          .button-wrapper {
            text-align: right;
            position: absolute;
            bottom: size(20);
            left: size(20);
            right: size(20);
            &::after {
              clear: both;
              content: '';
              display: block;
            }
            .access-link {
              float: left;
            }
          }
        }
      }
    }
    &.package-info {
      .section-wrapper {
        .plan-box {
          width: 100%;
          margin-bottom: size(20);
          // min-height: size(356);
          @media screen and (min-width: $breakpoint-md) {
            width: 48%;
            margin-right: size(10);
          }
          @media screen and (min-width: $breakpoint-lg) {
            width: size(340);
            margin-right: size(20);
            &:nth-child(3) {
              margin-right: size(20);
            }
            &:last-child {
              margin-right: 0;
            }
            // min-height: size(400);
          }
          .content-wrapper {
            h4 {
              max-width: size(160);
              @media screen and (min-width: $breakpoint-lg) {
                max-width: size(180);
              }
            }
          }
          &.paygo {
            display: none;
            .access-link {
              padding-top: size(10);
            }
          }
        }
      }
    }
  }
  .compare-plans {
    margin: size(30) 0;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: $breakpoint-md) {
      margin: size(40) 0;
    }
    a {
      font-size: size(15);
      font-weight: 500;
      line-height: size(16);
      text-decoration-line: underline;
      cursor: pointer;
    }
  }
  .pricing-subtitle {
    margin-bottom: size(24);
    font-size: size(42);
    line-height: size(56);
    font-weight: bold;
    @media screen and (min-width: $breakpoint-md) {
      margin-bottom: size(32);
    }
  }
  .package-price-wrapper {
    display: block;
    margin-bottom: size(40);
    @media screen and (min-width: $breakpoint-md) {
      display: flex;
      flex-wrap: wrap;
    }
    >div {
      padding: 0 size(20) size(20);
      border: size(2) solid #DADADA;
      margin: size(20) 0;
      border-radius: size(8);
      @media screen and (min-width: $breakpoint-md) {
        max-width: size(234);
        flex: 0 0 45%;
        padding: size(30);
        margin: 0 size(30) size(30) 0;
      }
      @media screen and (min-width: $breakpoint-lg) {
        flex: 0 0 25%;
        padding: 0 size(48) 0 0;
        margin: 0;
        border: none;
      }
      &:last-child {
        @media screen and (min-width: $breakpoint-lg) {
          padding-right: 0;
        }
      }

      &.enterprise {
        .content-wrapper {
          .subtitle {
            padding-bottom: size(10);
          }
        }
      }

      .subtitle {
        font-weight: 400;
        font-size: size(14);
        line-height: size(20);
        padding-bottom: size(30);
      }
      .annual-price {
        font-weight: 700;
        font-size: size(24);
        line-height: size(34);
        color: var(--rs-black-color);
        padding-bottom: size(4);

        span {
          font-size: size(14);
          line-height: size(18);
          font-weight: 500;
          color: #777;
        }
      }
      .markdown, .description {
        display: block;
        font-weight: 400;
        font-size: size(15);
        line-height: size(21);
        padding-top: size(20);
      }
      .description {
        ul {
          li {
            font-size: size(14);
            line-height: size(24);
            padding-bottom: size(8);
            margin-bottom: size(8);
            padding-left: size(20);
            border-bottom: 1px solid #E2E2E2;
            color: #000000;
            position: relative;
            &:first-child {
              padding-top: 0;
            }
            &::before {
              content: url('~@/assets/icon-tick.svg');
              display: inline-block;
              vertical-align: middle;
              position: absolute;
              left: 0;
              top: size(12);
              transform: translateY(-50%);
            }
            strong {
              font-weight: bolder;
            }
          }
        }
      }
      .title {
        display: block;
        font-weight: 700;
        font-size: size(16);
        line-height: size(22);
        text-transform: capitalize;
        color: var(--rs-black-color);
        padding-bottom: size(8);
      }

      .contact-sales-btn {
        background: var(--rs-black-color);
        color: var(--rs-white-color);
        border: size(1) solid var(--rs-black-color);
        padding: size(12) 0;
        line-height: size(21);
        font-size: var(--rs-title-level5-font-size);
        font-weight: bold;
        display: block;
        border-radius: size(5);
        margin-top: size(18);
        text-align: center;
        &:hover {
          @include jump-up-animation;
        }
        &:after {
          content: '';
          border: solid var(--rs-white-color);
          border-width: 0 size(2) size(2) 0;
          display: inline-block;
          padding: size(2.2);
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
          margin-left: size(5);
          vertical-align: middle;
        }
      }
      .content-wrapper {
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(324);
        }
        .image-block {
          img {
            max-width: 100%;
            margin-bottom: size(20);
            min-height: size(200);
          }
        }
      }
      .pricing {
        display: flex;
        flex-direction: column;
        justify-content: end;

        img {
          max-width: 90%;
        }
        @media screen and (min-width: $breakpoint-md) {
          min-height: size(96);
        }
      }
      .unit-label {
        font-weight: 400;
        font-size: size(12);
        line-height: size(17);

        span {
          font-weight: 500;
          color: var(--rs-black-color);
        }
      }
    }
  }
</style>

<style lang="scss">
  div {
    &.paygo {
      p {
        margin-bottom: size(65);
        display: inline-block;
      }
    }
    &.plan-box {
      .markdown {
        margin-bottom: size(65);
        display: inline-block;
        .p {
          font-size: size(12);
          line-height: size(17);
          padding-top: size(10);
          color: #000000;
        }
        em {
          font-style: italic;
          font-weight: normal;
        }
        strong {
          font-weight: bolder;
          em {
            font-weight: bolder;
          }
        }
        ul {
          list-style-type: disc;
          padding-left: size(20);
          li {
            font-size: size(12);
            line-height: size(17);
            padding-top: size(6);
            color: #000000;
          }
        }
      }
    }
  }
  .pricing-div {
    .package-price-wrapper {
      .standard-packages {
        .markdown {
          display: inline-block;
          em {
            font-style: italic;
            font-weight: normal;
          }
          strong {
            font-weight: bolder;
            em {
              font-weight: bolder;
            }
          }
          ul {
            li {
              font-size: size(14);
              line-height: size(24);
              padding-bottom: size(8);
              margin-bottom: size(8);
              padding-left: size(20);
              border-bottom: 1px solid #E2E2E2;
              color: #000000;
              position: relative;
              &:first-child {
                padding-top: 0;
              }
              &::before {
                content: url('~@/assets/icon-tick.svg');
                display: inline-block;
                vertical-align: middle;
                position: absolute;
                left: 0;
                top: size(12);
                transform: translateY(-50%);
              }
            }
          }
          &.list-view {
            padding-top: size(6);
            margin: 0;
            display: block;
            strong {
              cursor: pointer;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
</style>
